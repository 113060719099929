import { USER_ACTION_TYPES } from './actionTypes';
import {
    setCookie,
    DOMAIN_COOKIE_NAME,
    getAccountsDashboardUrl,
    DAARA_COOKIE_NAME,
    DAARA_TOKEN_PREFIX
} from '../services/utilityService';
import userService from '../services/userService';
import { features } from '../services/daara-config.json';
import { sessionService } from 'redux-react-session';

//////////////////// ACTIONS //////////////////////////

const login = () => {
    return {
        type: USER_ACTION_TYPES.LOGIN
    };
};

const loginSuccessful = (client) => {
    return {
        type: USER_ACTION_TYPES.LOGIN_SUCCESS,
        data: client
    };
};

const loginFailed = (error) => {
    return {
        type: USER_ACTION_TYPES.LOGIN_FAIL,
        data: error
    };
};

export const daaraLogout = () => {
    return {
        type: USER_ACTION_TYPES.DAARA_LOGOUT
    };
};

export const daaraLogoutSuccessful = (data) => {
    return {
        type: USER_ACTION_TYPES.DAARA_LOGOUT_SUCCESS,
        data: { data }
    };
};

export const daaraLogoutFailed = (error) => {
    return {
        type: USER_ACTION_TYPES.DAARA_LOGOUT_FAIL,
        data: {
            code: error.code,
            message: error.message
        }
    };
};

const updateProfileSuccessful = () => {
    return {
        type: USER_ACTION_TYPES.UPDATE_PROFILE_SUCCESS
    };
};

const setLoading = () => {
    return {
        type: USER_ACTION_TYPES.START_LOADING
    };
};

const stopLoading = () => {
    return {
        type: USER_ACTION_TYPES.STOP_LOADING
    };
};

export const forgotPassword = () => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD
    };
};

export const recoverPasswordSuccessful = () => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD_SUCCESS
    };
};

export const recoverPasswordFailed = (message) => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD_FAIL,
        data: message
    };
};

//////////////////// THUNKS //////////////////////////////

export const setUserLoading = (isLoading) => {
    return (dispatch) => {
        if (isLoading) {
            dispatch(setLoading());
        } else {
            dispatch(stopLoading());
        }
    };
};

export const authUser = (accessToken) => {
    return (dispatch) => {
        userService.auth(accessToken).then((user) => {
            refreshUser(dispatch, user);
        });
    };
};

export const loginUser = (id, password, history) => {
    return (dispatch) => {
        dispatch(login());
        userService
            .login(id, password)
            .then((user) => {
                saveSession(dispatch, user, history);
            })
            .catch((err) => {
                dispatch(loginFailed({ message: err.message, code: err.code }));
            });
    };
};

export const loginWithAccessToken = (accessToken, to) => {
    return (dispatch) => {
        dispatch(setUserLoading(true));
        userService
            .auth(accessToken)
            .then((user) => {
                saveSession(dispatch, user, to);
            })
            .catch((err) => {
                dispatch(loginFailed({ message: err.message, code: err.code }));
            });
    };
};

export const resetPassword = (values) => {
    //try getting fcmToken if permission already granted
    return (dispatch) => {
        dispatch(forgotPassword());
        userService.resetPassword(values).then(
            () => {
                dispatch(recoverPasswordSuccessful());
            },
            (err) => {
                const { data, statusText } = err.response || {
                    status: -1,
                    statusText: 'Unknown Error',
                    data: {}
                };
                dispatch(recoverPasswordFailed({ message: data.message || statusText }));
            }
        );
    };
};

const refreshUser = (dispatch, user) => {
    sessionService.loadUser().then(() => {
        sessionService.saveUser(user).then(() => {
            dispatch(updateProfileSuccessful());
        });
    });
};

const getDomain = () => {
    //set cookie domain
    let domain = '.reliancehmo.com';
    if (window.location.hostname.indexOf('localhost') > -1) {
        domain = 'localhost';
    } else if (window.location.hostname.indexOf('testing') > -1) {
        domain = `.testing${domain}`;
    } else if (window.location.hostname.indexOf('sandbox') > -1) {
        domain = `.sandbox${domain}`;
    }
    return domain;
};

const saveSession = (dispatch, user, history) => {
    //set accesstoken in cookie
    setCookie(DOMAIN_COOKIE_NAME, user.credentials.accessToken, 1, getDomain());
    sessionService.saveSession(user.credentials.accessToken).then(() => {
        sessionService.saveUser(user).then(() => {
            dispatch(loginSuccessful(user, history));
        });
    });
};

export const logoutUserFromDaaraService = () => {
    return async (dispatch) => {
        dispatch(daaraLogout());
        await sessionService
            .loadUser()
            .then((user) => {
                if (features.account_service.auth) {
                    localStorage.removeItem(`${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`);
                }
                fetch(`${process.env.REACT_APP_DAARA_ACCOUNT_SERVICE_URL}/logOut`, {
                    method: 'GET',
                    headers: new Headers({
                        Authorization: `Bearer ${user.credentials.daaraToken}`,
                        'Content-Type': 'application/json'
                    })
                })
                    .then((response) => dispatch(daaraLogoutSuccessful(response)))
                    .catch((error) => dispatch(daaraLogoutFailed(error)));
            })
            .catch((error) => dispatch(daaraLogoutFailed(error)));
    };
};

export const logoutUser = (from) => {
    return () => {
        sessionService
            .deleteUser()
            .then(() => {
                sessionService.deleteSession().then(() => {
                    let destUrl = `${getAccountsDashboardUrl()}/logout`; //auth is dummy route, it actually goes to /dashboard/
                    window.location.replace(destUrl + (from ? `?r=${from}` : ``));
                });
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const updateLocalUser = (user) => {
    return sessionService.saveUser(user);
};
