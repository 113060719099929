import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component{

    render(){
      if(this.props.open){
        return (
          <div className={`modal open`}>
            <div className="modal-content">
              <span className="modal-close" onClick={this.props.onClose}>&times;</span>
              <h3 className="modal-header">{this.props.title}</h3>
              {this.props.children}
            </div>
          </div>
        )
      }else {
        return null;
      }
    }

}

Modal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.object,
    onClose: PropTypes.func,
    open:PropTypes.bool.isRequired
};

export default Modal;