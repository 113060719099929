import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'querystring';
import { logoutUser, logoutUserFromDaaraService } from '../actions/user';
import LoadingScreen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';

const LogoutContainer = ({location,logout}) => {
  if(logout){
    //check if referred from location
    const from = queryString.parse(location.search.substr(1)).r;
    logout(from);
  }

  return (
    <MainContent>
      <LoadingScreen/>
    </MainContent>
  );
};

const mapDispatch = dispatch => ({
  logout: (from) => {
    dispatch(logoutUserFromDaaraService());
    dispatch(logoutUser(from));
    //dispatch(clearUserProfile());
  }
});

export default withRouter(connect(null, mapDispatch)(LogoutContainer));