import React from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

class FilterableSelect extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      items: props.items,
      filteredItems:[],
      filterBy:props.filterBy,
      query: '',
      showDropDown:false,
      selectedItem:null
    }
  }

  componentDidMount(){
    let preSelected = this.props.items.filter(item =>item.id === this.props.input.value)[0];

    this.setState({
      filteredItems: this.state.items,
      selectedItem: preSelected || null,
      query: preSelected ? preSelected.name : ''
    })
  }

  filterList = (query) => {
    if(query.length === 0){
      this.setState({
        filteredItems:this.state.items,
        query:query
      })
    }else{
      const l = this.state.items.filter(item=>{
        return this.state.filterBy.some(key=>{
          return _.get(item, key, '').toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      });
      if(l.length === 0)
        l.push({id:-1,name:"no result"});
      this.setState({
        filteredItems:l,
        query:query
      })
    }
  };

  toggleDropDown = (show) => {
    this.setState({
      showDropDown:show
    })
  };

  setValue = (selectedItem) => {
    const {input:{onChange}} = this.props;
    this.setState({
      selectedItem,
      query:selectedItem.name,
      showDropDown:false
    });
    if(onChange)
      onChange(selectedItem.id);
  };



  render(){

    const {filteredItems,showDropDown, query, selectedItem} = this.state;
    const {name,placeholder}=this.props;

    return (
      <div style={{display:"flex",flexDirection:"column", position:"relative"}}>
        <input type="hidden" value={selectedItem ? selectedItem.id : -1} name={name}/>
        <input
          type="text"
          placeholder={placeholder}
          onInput={(e)=>this.filterList(e.target.value)}
          onFocus={()=>this.toggleDropDown(true)}
          onBlur={()=>this.toggleDropDown(false)}
          value={query}
        />
        {showDropDown &&
          <ul style={{maxHeight:"20rem",overflowY:"scroll",borderBottom:"1px solid rgba(9, 64, 99, 0.1)",zIndex:"9999", position:"absolute",top:"100%",left:"0", width:"100%",background:"white"}} >
            {filteredItems.map(item=>(
              <li key={item.id} style={{cursor:"pointer"}} onMouseDown={()=>this.setValue(item)}>
                <div style={{borderBottom:"1px solid #eee",padding:"0.5rem"}}>
                  <p style={{marginBottom:"0.5rem"}}>{item.name}</p>
                  {this.props.extraKey && <p style={{fontSize:"0.8rem",color:"#aaa"}}>{item[this.props.extraKey]}</p>}
                </div>
              </li>
            ))}
          </ul>
        }
      </div>
    )
  }


}


FilterableSelect.propTypes = {
  items:PropTypes.array,
  filterBy:PropTypes.array,
  placeholder:PropTypes.string,
  extraKey:PropTypes.string
};

export default FilterableSelect;