import axios from 'axios';
import querystring from 'querystring';
import {BASE_URL_RELIANCE, getCookie,DOMAIN_COOKIE_NAME } from './utilityService';

const BASE_URL = BASE_URL_RELIANCE;

export const PARTNER_ENDPOINTS = {
  base:`${BASE_URL}`,
  agents: {
    base: (partnerId) => `/partners/${partnerId}/agents`,
    agent: (partnerId, agentId) => `/partners/${partnerId}/agents/${agentId}`
  },
  transactions: {
    allTransactions: (partnerId) => `/partners/${partnerId}/wallet-transactions`
  }
};

export class PartnersService {
  constructor(accessToken=getCookie(DOMAIN_COOKIE_NAME), BASE) {
    this.ax = axios.create({
      baseURL: BASE || `${BASE_URL}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });

    this.data = {};
  }

  //overview,profile,transactions,products


  post = (url, data) => {
    return this.ax.post(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new PartnersServiceException(e);
      });
  };

  put = (url, data) => {
    return this.ax.put(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new PartnersServiceException(e);
      });
  };

  get = (url, data) => {
    return this.ax.get(`${url}?${querystring.stringify(data)}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new PartnersServiceException(e);
      });
  };

  delete = (url, data) => {
    return this.ax.delete(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new PartnersServiceException(e);
      });
  };
}

function PartnersServiceException(e, _meta) {
  if (!e.response)
    e = {response: {status: 0, statusText: "Unknown Error", data: {message: "An Error Occurred"}}};
  this.code = e.response.status;
  this.message = e.response.data.message || e.response.statusText;
  this.meta = _meta;
}

export default new PartnersService();