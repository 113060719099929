import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MainContent from '../boxes/MainContent';
import PageHeader from '../boxes/PageHeader';
import PageBody from '../boxes/PageBody';

let AddAgentForm = ({handleSubmit}) =>{
  return (
    <MainContent>
      <PageHeader title="Add Agent"/>
      <PageBody>
        <div className="dashboard-card">
          <form onSubmit={handleSubmit} className="dashboard-form">
            <div className="dashboard-form-item-wrapper">
              <div className="dashboard-input-wrap profile-input-wrap">
                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    First Name
                  </label>
                  <Field component="input" className="dashboard-form__input" type="text" placeholder="Enter first name..." name="first_name" required/>
                </span>

                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    Last Name
                  </label>
                  <Field component="input" className="dashboard-form__input" placeholder="Enter last name..." name="last_name" required/>
                </span>

                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    Email Address
                  </label>
                  <Field component="input" className="dashboard-form__input"  placeholder="Enter email address..." name="email_address"/>
                </span>

                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    Phone Number
                  </label>
                  <Field component="input" className="dashboard-form__input"  type="number" placeholder="Enter phone number..." name="phone_number" required/>
                </span>

                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    User Role
                  </label>
                  <Field
                    component="select"
                    name="selected_package"
                    className="dashboard-form__input dashboard-form__select"
                  >
                    <option>Please select a Role</option>
                    <option value="1">Partner</option>
                    <option value="2">Agent</option>
                  </Field>
                </span>

                <button
                  className="dashboard__primary-btn dashboard-form__btn"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </PageBody>
    </MainContent>
  );
}

AddAgentForm = reduxForm({
form: "addAgentForm"
})(AddAgentForm);

export default AddAgentForm;