import React from 'react';
import {withRouter} from 'react-router-dom';
import {updateLocalUser} from "../actions/user";
import * as _ from 'lodash';
import LoadingScreen from '../components/LoadingScreen';
import LOGO from '../logo.svg';
import CLIENT_ICON from '../client_dash_icon.svg';
import {withUser} from "../components/utils/AuthHOC";



class WelcomeContainer extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading:false
    }
  }

  onSelectPartner = (selectedPartner) => {
    const {user,history} = this.props;
    this.setState({
      isLoading: true
    });
    user.preferences.selectedPartner = selectedPartner;
    updateLocalUser(user)
      .then(() => {
        history.push(`/dashboard/overview`);
      })
  };

  render() {
    const {isLoading} = this.state;
    const {user} = this.props;

    if (!isLoading && !_.isEmpty(user)) {
      const partners = user.partners;

      return (
        <div className="container">
          <div className="col-flex welcome-wrapper">
            <img src={LOGO} alt="RelianceHMO" className="logo"/>
            <h1>Welcome</h1>
            <h4>Please select the Client you'd like to access?</h4>
            <ul className="row-flex action-chooser">
              {partners.map(partner => (
                <li key={partner.id}>
                  <div className="action-item" onClick={() => {
                    this.onSelectPartner(partner);
                  }}>
                    <img src={CLIENT_ICON} alt={partner.name} className="action-item__icon"/>
                    <div className="action-item__label">
                      {partner.name}
                    </div>
                    <div className="label">{partner.role}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="col-flex welcome-wrapper">
          <LoadingScreen/>
        </div>
      </div>
    )
  }
}

export default withRouter(withUser(WelcomeContainer));