import React from 'react';
import {Field, reduxForm} from 'redux-form';

class ChangePasswordForm extends React.Component {

  render() {
    const {handleSubmit, pristine, submitting} = this.props;

    return (
      <form onSubmit={handleSubmit} style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
        <Field component="input" className="form-input" type="password" name="old_password" placeholder="Old Password"/>
        <Field component="input" className="form-input" type="password" name="new_password" placeholder="New Password"/>
        <Field component="input" className="form-input" type="password" name="confirm_new_password"
               placeholder="Confirm New Password"/>
        <Field component="input" type="hidden" name="access_token" style={{display: "none"}}/>
        <button className="form-button button_link" disabled={pristine || submitting}>
          Change Password
        </button>
      </form>
    )
  }
}


ChangePasswordForm = reduxForm({
  form: "changePasswordForm"
})(ChangePasswordForm);

export default ChangePasswordForm;