import axios from 'axios';
import querystring from 'querystring';
import {BASE_URL_RELIANCE_PUBLIC, AUTH_USERNAME, AUTH_PASSWORD} from './utilityService';

const BASE_URL = BASE_URL_RELIANCE_PUBLIC;

export const ENDPOINTS = {
  base:`${BASE_URL}/public`,
  overview: '/overview',
  wallet: {
    getWalletBalance: '/wallet',
    fundWallet: '/wallet/fund',
    transactions: '/wallet/transactions'
  },
  enrollees: {
    signUpEnrollee: '/retail/signup',
    getEnrollees: '/enrollees',
    enrolleeDetails: (id) => `/enrollees/${id}`,
    registerEnrollee: '/enrollees/profile',
    getEnrolleeId: (hmoId) => `/enrollees/id-card?hmo_id=${hmoId}`
  },
  utilities: {
    states: '/utilities/states',
  },
  plans: {
    getPlans: '/plans',
    renewPlan: `/retail/renew`
  }
};

export class ApiService {
  constructor(accessToken, username, password ) {
    this.ax = axios.create({
      baseURL: `${BASE_URL}/public`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      auth: {
        username: username || AUTH_USERNAME,
        password: password || AUTH_PASSWORD
      }
    });

    this.data = {};
  }

  //overview,profile,transactions,products

  post = (url, data) => {
    return this.ax.post(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new ApiServiceException(e);
      });
  };

  put = (url, data) => {
    return this.ax.put(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new ApiServiceException(e);
      });
  };

  get = (url, data) => {
    return this.ax.get(`${url}?${querystring.stringify(data)}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new ApiServiceException(e);
      });
  };
}

function ApiServiceException(e, _meta) {
  if (!e.response)
    e = {response: {status: 0, statusText: "Unknown Error", data: {message: "An Error Occurred"}}};
  this.code = e.response.status;
  this.message = e.response.data.message || e.response.statusText;
  this.meta = _meta;
}

export default new ApiService();