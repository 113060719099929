import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import numberFormatter from 'number-formatter';
import { PulseLoader } from 'react-spinners';
import { ApiService, ENDPOINTS } from '../../services/ApiService';
import Modal from "../boxes/Modal";
import {withUser} from "../utils/AuthHOC";
import {getDomain} from "../../services/utilityService";

class FundWalletModal extends Component {
  constructor(props){
    super(props);
    const {user: {preferences : { selectedPartner: { credentials } }}, user} = props;
    this.service = new ApiService(user.credentials.access_token, credentials.api_key,credentials.secret_key);
    this.state = {
      walletBalance: 0,
      walletFundDetails: [],
      isFetching: true,
      isSubmitting: false,
      fundWalletSuccess: false,
    };
  }

  componentDidMount () {
    this.getWalletBalance();
  }

  onFundWallet = (values) => {
    const amount = String(Number(values.amount)*100);
    this.service.post(ENDPOINTS.wallet.fundWallet, {amount, redirect_url:`${getDomain("partners")}/payment-successful`})
      .then((response) => {
        this.setState({
          walletFundDetails: response.data,
          isSubmitting: true,
          fundWalletSuccess: true
        });
        
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        console.log(error)
      })
  };

  getWalletBalance = () => {
    this.service.get(ENDPOINTS.wallet.getWalletBalance)
      .then((response) => {
        this.setState({
          walletBalance: response.data.balance,
          isFetching: false
        })
      })
      .catch((error) => {
        console.log(error)
      })
  };

  render () {
    const { onClose, showFundWalletModal, handleSubmit } = this.props;
    const { isSubmitting, walletBalance, isFetching } = this.state;

    return (
      <Modal title="Fund Wallet" onClose={onClose} open={showFundWalletModal}>
        <div>
          <div className="dashboard-card stats-card" style={{justifyContent:"space-between"}}>
            <div className="dashboard-card__text--left-align">
              <p className="dashboard-card__title-text">
                Wallet Balance
              </p>
              
                {
                  isFetching ?
                    <PulseLoader/>
                    :
                    <p className="dashboard-card__data-text">
                      ₦{numberFormatter("#,##0.00",walletBalance)}
                    </p>
                }
            </div>
            <i className="fa fa-2x fa-money stats-icon"/>
          </div>
          <form 
            onSubmit={handleSubmit(this.onFundWallet)} 
            className="dashboard-form"
          >
            <div className="dashboard-form-item-wrapper">
              <div className="dashboard-input-wrap profile-input-wrap">
                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                      Enter an Amount
                  </label>
                  <Field 
                    component="input" 
                    className="dashboard-form__input" 
                    type="number" 
                    placeholder="Enter the amount to fund your wallet" 
                    name="amount" 
                    required/>
                </span>

                <button className="dashboard__primary-btn dashboard-form__btn" type="submit">
                  {
                    isSubmitting ? 
                      <PulseLoader color="#ffffff" size={7}/> 
                      : 
                      'Submit'
                  }
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

FundWalletModal = reduxForm({
  form: "fundWalletModal"
  })(FundWalletModal);

export default withUser(FundWalletModal);