import React from 'react';
import Proptypes from 'prop-types';
import MainContent from './boxes/MainContent';
import PageBody from './boxes/PageBody';
import Modal from "./boxes/Modal";
import LOGO from '../logo.svg';

const NotAuthorizedComponent= ({redirectUrl, message}) => {
  return (
    <MainContent>
      <div className="top-right content">
        <div className="header" style={{height:"auto",justifyContent:"center", padding:"3rem 2rem"}}>
          <div className="logo">
            <img src={LOGO} alt=""/>
          </div>
          <div className="header__title">
            <h2> </h2>
          </div>
        </div>
      </div>
      <PageBody>
        <Modal title="Notification" onClose={()=>window.location.replace(redirectUrl)} open={true}>
          <div style={{display:"flex",flexDirection: "column",padding:"1rem"}}>
            <p style={{fontSize:"2rem"}}>{message || "Sorry, an error seems to have occurred."}</p>
            <button className="primary_button" onClick={()=>window.location.replace(redirectUrl)}>
              Click here to go back.
            </button>
          </div>
        </Modal>
      </PageBody>
    </MainContent>
  )
};

NotAuthorizedComponent.propTypes = {
  redirectUrl: Proptypes.string,
  message: Proptypes.string
};

export default NotAuthorizedComponent;