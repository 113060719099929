
const defaultStore = {
    user: {
        isFetching:true,
        isLoggedIn: false,
        invalidated:false,
        alert:"",
        error:null
    },
    // guardian:{
    //     isFetching:false,
    //     isLoggedIn: false,
    //     invalidated:false,
    //     alert:"",
    //     error:{
    //         code:0,
    //         message:""
    //     },
    //     overViewData:{},
    //     usageData:{
    //         isFetching:false,
    //         data:[],
    //         summary:{
    //             totalQuestionsAsked:0,
    //             totalTipsRead:0,
    //             totalVisits:0
    //         }
    //     }
    // },
    invoice:{
        isFetching:true,
        invoices:[],
        selectedInvoice:{},
        error:{},
        alert:""
    },
    enrollees:{
        isFetching:true,
        allEnrollees:[],
        selectedEnrollee:{},
        error:{},
        alert:""
    },
    display:{
        showSideBar:false
    }
};

export default defaultStore;