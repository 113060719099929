class SortTableData {
  static ascendingTableSort(key, data) {
    return data.sort((curr, next) => curr[key] > next[key] ? 1 : -1);
  }

  static descendingTableSort(key, data) {
    return data.sort((curr, next) => next[key] < curr[key] ? -1 : 1)
  }
}

export default SortTableData;