import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import LOGO from '../logo.svg';
import SidebarMenuItem from '../components/SidebarMenuItemComponent';


const items = [
  {
    path: "/dashboard/profile",
    icon: "fa fa-user",
    title: "Profile"
  },
  {
    path: "/dashboard/enrollees",
    icon: "fa fa-users",
    title: "Enrollees"
  },
  {
    path: "/dashboard/agents",
    icon: "fa fa-handshake-o",
    title: "Agents"
  },
  {
    path: "/dashboard/transactions",
    icon: "fas fa-credit-card",
    title: "Transactions"
  }
];

class SidebarContainer extends React.Component {

  render() {
    const {showSideBar, userName, user} = this.props;
    
    return (
      <div id="left" className={`column sidebar ${showSideBar ? '' : 'active'}`}>
        <div className="top-left">
          <div className="sidebar__top sidebar__top--partners">
            {/* <img src={GUARDIAN_STUB} alt="Company"/> */}
            <p>Welcome</p>
            <p><strong>{userName.toUpperCase() || "USER"}</strong></p>
          </div>
        </div>
        <div className="bottom">
          <nav>
            <ul>
              {/* {isEnrollee && <li className="dashboard__menu">
                <i className="fa fa-user"/>
                <a href={enrolleeDashboardUrl}>
                  My Profile
                </a>
              </li>} */}
              {
                user.preferences.selectedPartner.role === "partner" &&
                  <SidebarMenuItem
                    to="/dashboard/overview"
                    icon="fa fa-tachometer"
                    title="Overview"/>
              }
              {items.map((item, i) => (
                <SidebarMenuItem
                  key={i}
                  to={item.path}
                  icon={item.icon}
                  title={item.title}/>
              ))}
              <li className="dashboard__menu"><i className="fa fa-question-circle"/><a
                href="mailto:hello@reliancehmo.com?subject=Client Dashboard Help[from our client]">Help</a></li>
              <li className="dashboard__menu">
                <a href="/logout">
                  <i className="fa fa-sign-out"/>
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="selected-client-wrapper">
          <p className="text-label">Selected Client:</p>
          <h2 className="text-main">
            {user.preferences.selectedPartner.name}
            {user.partners.length > 0 &&
              <span
              className="text-link"
              onClick={()=>this.props.history.push('/welcome')}>
                (Change)
              </span>
            }
          </h2>
          <span className="label">{user.preferences.selectedPartner.role}</span>
        </div>
        <div className="sidebar__bottom">
          <img src={LOGO} alt="RelianceHMO's Logo"/>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {showSideBar} = state.display || {};
  return {showSideBar};
}

export default withRouter(connect(mapStateToProps)(SidebarContainer));