import React from 'react';
import {Switch, withRouter, Redirect, Route} from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import {DISPLAY_ACTION_TYPES} from '../actions/actionTypes';
import SidebarContainer from './SidebarContainer';
import EnrolleeComponent from "../components/EnrolleeComponent";
import OverviewContainer from "../containers/OverviewContainer";
import ProfileContainer from "../containers/ProfileContainer"
import TransactionListContainer from "../containers/TransactionListContainer";
import AgentListContainer from "../containers/AgentListContainer";
import AgentDetailContainer from "../containers/AgentDetailContainer"
import AddAgentComponent from "../components/AddAgentComponent";
import PageNotFoundComponent from "../components/PageNotFoundComponent";
import ChangePasswordComponent from "../components/ChangePasswordComponent";
import PropsRoute from "../components/utils/PropsRoute";

class DashboardContainer extends React.Component {

  constructor(props) {
    super(props);

    const {dispatch} = props;
    this.state = {
      isLoading:true
    };
    // this.state = {
    //   referrer: queryString.parse(location.search.substr(1)).r || window.location.href
    // };

    props.history.listen(() => {
      dispatch({type: DISPLAY_ACTION_TYPES.HIDE_SIDEBAR});
    });
  }

  componentDidMount(){
    const {user,history} = this.props;
    if(user.preferences.selectedPartner === null){
      history.push(`/welcome`);
    }else{
      this.setState({
        isLoading: false
      });
    }
  }


  renderLoading() {
    return <LoadingScreen/>;
  }


  render() {
    const {user} = this.props;
    let authenticated = true;


    if(user.preferences.selectedPartner === null){
      return <Redirect to="/welcome"/>
    }
    // if (!_.isEmpty(user)) {

      // const redirectUrl = user.roles.filter(role=>role.can_access).length > 0  ? user.roles.filter(role=>role.can_access)[0].url : '/';

      return (
        <div className="dash">
          {/*{!user.roles.filter(role=>role.name.toLowerCase() === 'user')[0].can_access && <NoAccessModal redirectUrl={redirectUrl}/>}*/}
          <SidebarContainer userName={`${user.firstName} ${user.lastName}`} user={user}/>
          <Switch>
            <PropsRoute component={OverviewContainer} path="/dashboard/overview"
                          authenticated={authenticated} user={user}/>
            <PropsRoute component={ProfileContainer} path="/dashboard/profile"
                          authenticated={authenticated} user={user}/>
            <PropsRoute component={EnrolleeComponent} path="/dashboard/enrollees"
                          authenticated={authenticated} user={user}/>
            <PropsRoute component={AddAgentComponent} path="/dashboard/agents/add-agent"
                          authenticated={authenticated} user={user} exact/>
            <PropsRoute component={AgentDetailContainer} path="/dashboard/agents/:id"
                          authenticated={authenticated} user={user} exact/>
            <PropsRoute component={AgentListContainer} path="/dashboard/agents"
                          authenticated={authenticated} user={user}/>
            <PropsRoute component={TransactionListContainer} path="/dashboard/transactions"
                          authenticated={authenticated} user={user}/>
            <PropsRoute component={ChangePasswordComponent} path="/dashboard/change-password"
                          authenticated={authenticated} user={user}/>
            <Route path="/dashboard/logout" exact render={(props)=> <Redirect to="/logout"/>}/>
            <Route path="/dashboard/" exact render={(props) => <Redirect to="/dashboard/overview"/>}/>
            <Route component={PageNotFoundComponent}/>
          </Switch>
        </div>
      );
    // }
    // const referrer = this.state.referrer;
    // if (checked && !authenticated) return (<Redirect to={`/login${referrer ? `?r=${referrer}` : `` }`}/>);
  }
}

export default withRouter(/**connect(mapStateToProps)**/(DashboardContainer));