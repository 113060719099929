import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CenteredContentBlock from '../components/boxes/CenteredContentBlock';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import LoadingScreen from '../components/LoadingScreen';
import { PartnersService, PARTNER_ENDPOINTS } from "../services/PartnersService";
import RestrictedComponent from "../components/boxes/RestrictedComponent";

class AgentListContainer extends Component {
  constructor(props){
    super(props);
    this.partnersService = new PartnersService(props.user.credentials.accessToken);
    this.state = {
      agents: [],
      isFetching: false,
    }
  }

  componentDidMount () {
    this.setIsFetching(true);
    this.getAgents()
  }

  addAgent = ()  => {
    this.props.history.push("/dashboard/agents/add-agent");
  };

  getAgents = () => {
    const { user: {preferences: {selectedPartner } }  } = this.props;
    this.partnersService.get(PARTNER_ENDPOINTS.agents.base(selectedPartner.id))
      .then((response) => {
        this.setState({
          agents: response.data,
          isFetching: false
        })
      })
      .catch((error) => {
        this.setIsFetching(false);
        console.log(error)
      })
  };

  setIsFetching = (isFetching) => {
    this.setState({
      isFetching
    })
  };

  deleteSelectedAgent = (agentId) => {
    this.setIsFetching(true);
    const { history, user: {preferences: {selectedPartner } }   } = this.props;
    this.partnersService.delete(PARTNER_ENDPOINTS.agents.agent(selectedPartner.id, agentId))
      .then(() => {
        history.push('/dashboard/agents');
      })
      .catch((error) => {
        this.setIsFetching(false);
        console.log(error);
      })
  };

  renderEmptyState() {
    return (
      <CenteredContentBlock>
        <div className="dashboard-card">
          <div className="empty-state-wrap">
            <i className="fa fa-users" style={{fontSize: "4rem"}}/>
            <br/><br/><br/>
            <p className="dashboard-card__title-text" 
            style={{textAlign: "center"}}
            >
              No Agent Available
              <br/>
            </p>
            <div className="">
              <button 
                onClick={() => this.props.history.push('/dashboard/agents/add-agent')}
                className="dashboard__primary-btn dashboard-control__button" 
                style={{width: "auto"}}
              >
                Create Agent
              </button>
            </div>
          </div>
        </div>
      </CenteredContentBlock>
    );
  }

  agentsListCheck() {
    const { agents } = this.state;
    const { history } = this.props;

    return (
      agents.length === 0 ?
        this.renderEmptyState()
        :
        <div className="dashboard-card dashboard-table-wrap">
          <div className="dashboard-table-overflow">
            <table className="dashboard-table">
              <thead>
                <tr>
                  <th className="dashboard-table-item dashboard-table-item__header">Name</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Email</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Phone Number</th>
                  <th className="dashboard-table-item dashboard-table-item__header">No of plans sold</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Code</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  agents.map((agent, index) => (
                    <tr key={agent.id}>
                      <td className="dashboard-table-item dashboard-table-item__body">
                        {agent.first_name} {agent.last_name}
                      </td>
                      <td className="dashboard-table-item dashboard-table-item__body">{agent.email_address ? agent.email_address : 'N/A'}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{agent.phone_number ? agent.phone_number : 'N/A'}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{agent.total_no_policies_sold}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{agent.referral_code ? agent.referral_code : 'N/A'}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">
                        <div style={{display: "flex"}} >
                          <div>
                            <button className="dashboard__primary-btn" onClick={() => (history.push(`/dashboard/agents/${agent.id}`))}>
                              <i className="fa fa-eye"/>
                            </button>
                          </div>
                          <div>
                            <button className="dashboard__primary-btn-red" onClick={() => this.deleteSelectedAgent(agent.id)} >
                              <i className="fa fa-trash" style={{color: "white"}}/>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
    )
  }

  render() {
    const { isFetching } = this.state;
    const { user: { preferences: { selectedPartner } }} = this.props;

    return (
      <MainContent>
        <PageHeader 
          title="Agents"
          buttonTitle="Add Agent"
          showButton
          onButtonClick={this.addAgent}
        />
        <PageBody>
          {
            isFetching ? 
              <LoadingScreen/>
              :
              <RestrictedComponent
                condition={selectedPartner.role === "partner"}
                displayErrorMessage>
                {this.agentsListCheck()}
              </RestrictedComponent>
          }
        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(AgentListContainer);