import React from 'react';
import { PartnersService, PARTNER_ENDPOINTS } from '../services/PartnersService';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';

class AgentDetailContainer extends React.Component {

  constructor(props) {
    super(props);
    this.partnersService = new PartnersService(props.user.credentials.accessToken);
    this.state = {
      selectedAgent: {},
      isFetching: true
    }
  }
  
  componentDidMount() {
    this.getSelectedAgent()
  }

  getSelectedAgent = () => {
    const { match: { params: { id }}, user: { preferences: { selectedPartner }}} = this.props;
    this.partnersService.get(PARTNER_ENDPOINTS.agents.agent(selectedPartner.id, id))
      .then((response) => {
        this.setState({
          selectedAgent: response.data,
          isFetching: false
        })
      })
      .catch((error) => {
        console.log(error)
      })
  };

  deleteSelectedAgent = () => {
    const { match: { params: { id }}, history } = this.props;
    this.partnersService.delete(PARTNER_ENDPOINTS.agents.agent(249, id))
      .then((response) => {
        history.push('/dashboard/agents');
      })
      .catch((error) => {
        console.log(error)
      })
  };

  viewEnrollees = () => {
    const {history} = this.props;
    const {selectedAgent} = this.state;
    history.push(`/dashboard/enrollees?agent=${selectedAgent.referral_code}`);
  };

  render() {
    const { selectedAgent, isFetching } = this.state;

    return (
      <MainContent>
        <PageHeader title="Agent Profile"/>
        <PageBody>
          {
            isFetching ?
              <LoadingScreen/>
              :
              <React.Fragment>
                <div className="dashboard-card button-header">
                  <button
                    className="dashboard__primary-btn-red"
                    onClick={this.deleteSelectedAgent}>
                    <i className="fa fa-trash" style={{color: "white"}}/>
                    {'  '}
                    Remove Agent
                  </button>
                  <button
                    className="dashboard__primary-btn"
                    onClick={this.viewEnrollees}>
                    <i className="fa fa-users" style={{color: "white"}}/>
                    {'  '}
                    View Enrollees
                  </button>
                </div>

                <div>
                  <div className="dashboard-card">
                    <h2 className="agent-header-text">Agent Details</h2>
                    <div className="profile-section">
                      <div className="profile-detail-wrap">
                        <div className="profile-item agent-profile-item">
                          <div className="profile-item-wrapper agent-wrapper">
                            <p className="profile-item__label">Name</p>
                            <p className="profile-item__text">{selectedAgent.first_name} {selectedAgent.last_name}</p>
                          </div>

                          <div className="profile-item-wrapper agent-wrapper">
                            <p className="profile-item__label">Email Address</p>
                            <p className="profile-item__text">{selectedAgent.email_address ? selectedAgent.email_address : 'N/A'}</p>
                          </div>

                          <div className="profile-item-wrapper agent-wrapper">
                            <p className="profile-item__label">Phone Number</p>
                            <p className="profile-item__text">{selectedAgent.phone_number ? selectedAgent.phone_number : 'N/A'}</p>
                          </div>

                          <div className="profile-item-wrapper agent-wrapper">
                            <p className="profile-item__label">Code</p>
                            <p className="profile-item__text">{selectedAgent.referral_code ? selectedAgent.referral_code : 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2 className="agent-header-text">Performance</h2>
                    <div className="dashboard-card dashboard-table-wrap">
                      {selectedAgent.sales.length > 0 ? (
                        <div className="dashboard-table-overflow">
                          <table className="dashboard-table">
                            <thead>
                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Plan Name</th>
                              <td className="dashboard-table-item dashboard-table-item__header">Number Sold</td>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedAgent.sales.map((sale,index) => (
                              <tr key={`${index}_${sale.plan_name}`}>
                                <th className="dashboard-table-item dashboard-table-item__header">{sale.plan_name}</th>
                                <td className="dashboard-table-item dashboard-table-item__body">{sale.amount_sold}</td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="dashboard-card">
                          <p className="profile-item__text">This agent hasn't made any sale yet.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
          }
        </PageBody>
      </MainContent>
    );
  }
}

export default AgentDetailContainer;