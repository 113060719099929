import React, { Component } from 'react';
import AddAgentForm from '../components/forms/AddAgentForm';
import { PartnersService, PARTNER_ENDPOINTS } from '../services/PartnersService';

class AddAgentComponent extends Component {
  constructor(props) {
    super(props);
    this.partnersService = new PartnersService(props.user.credentials.accessToken);
  }

  onSubmitAgent = (values) => {
    const {user: {preferences: {selectedPartner}}} = this.props;
    this.partnersService.post(PARTNER_ENDPOINTS.agents.base(selectedPartner.id), values)
      .then((response) => {
        window.location.href = '/dashboard/agents';
      })
      .catch((error) => {
        console.log(error);
      })

  };

  render () {
    return (
      <AddAgentForm 
        onSubmit={this.onSubmitAgent}
      />
    );
  }
}

export default AddAgentComponent;