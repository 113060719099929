/**
 * Created by the.last.mayaki on 20/10/2017.
 */
import React from 'react';

const CenteredContentBlock = (props) => (
    <div style={{margin:"0",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
        {props.children}
    </div>
);

export default CenteredContentBlock
