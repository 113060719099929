import axios from "axios";
import Cookies from "js-cookie";
import { occupations, maritalStatuses } from "./constants";

export const isDevelopment = () => {
  return process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "daara";
};

export const isProduction = () => {
  return process.env.REACT_APP_ENV === "production";
}
 
export const isStaging = () => {
  return process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "daara-staging";
};

export const isLocal = () => {
  return process.env.REACT_APP_ENV === "local";
};

export const getAccountsDashboardUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case "local":
      return "http://localhost:3000";
    case "staging":
      return "https://accounts.staging.reliancehmo.com";
    case "development":
      return "https://accounts.develop.reliancehmo.com";
    case "daara":
      return "https://accounts.daara.develop.reliancehmo.com";
    case "v3test":
      return "https://accounts.front.reliancehmo.com";
    case "daara-staging":
      return "https://accounts.daara.staging.reliancehmo.com";
    default:
      return "https://accounts.reliancehmo.com";
  }
};

export const getDomain = (subdomain) => {
  switch (process.env.REACT_APP_ENV) {
    case "local":
      return "http://localhost:3000";
    case "staging":
      return `https://${subdomain + "."}testing.reliancehmo.com`;
    case "development":
      return `https://${subdomain + "."}develop.reliancehmo.com`;
    case "daara":
      return `https://${subdomain+"."}daara.develop.reliancehmo.com`;
    case "daara-staging":
      return `https://${subdomain+"."}daara.staging.reliancehmo.com`;
    case "v3test":
      return `https://${subdomain+"."}front.reliancehmo.com`;
    default:
      return `https://${subdomain + "."}reliancehmo.com`;
  }
};

// export const getRedirectDomain = () => {
//   if(isLocal()){
//     return "http://localhost:3001";
//   }else{
//     return isDevelopment() ? "https://dashboard.testing.reliancehmo.com" : "https://dashboard.reliancehmo.com"
//   }
// };

export const BASE_URL_RELIANCE = process.env.REACT_APP_API_BASE_URL;
export const BASE_URL_RELIANCE_V3 = process.env.REACT_APP_API_BASE_URL_V3;

export const BASE_URL_RELIANCE_PUBLIC =
  process.env.REACT_APP_PUBLIC_API_BASE_URL;

export const DOMAIN_COOKIE_NAME = isProduction() ? "rat" : "rat_"+process.env.REACT_APP_ENV;

export const DAARA_TOKEN_PREFIX = process.env.REACT_APP_DAARA_TOKEN_PREFIX || 'PARTNERS';

export const REFRESH_TOKEN_NAME = process.env.REACT_APP_REFRESH_NAME;

export const DAARA_COOKIE_NAME = 'DAARA';

export const AUTH_USERNAME = process.env.REACT_APP_AUTH_USERNAME;

export const AUTH_PASSWORD = process.env.REACT_APP_AUTH_PASSWORD;

export const PLAN_TYPE_INDIVIDUAL = "individual";

export const PLAN_TYPE_FAMILY = "family";

export const PACKAGE_TYPE_RETAIL = "retail";

export const PACKAGE_TYPE_CUSTOM = "custom";

export const PACKAGE_TYPE_CORPORATE = "corporate";

export const decodeResourceStatus = (statusCode) => {
  switch (statusCode) {
    case 1:
      return "active";
    case 2:
      return "pending";
    case 3:
      return "deleted";
    case 4:
      return "suspended";
    case 5:
      return "expired";
    case 6:
      return "deactivated";
    case 7:
      return "delayed";
    default:
      return "";
  }
};

export const WALLET_TRANSACTION_TYPES = {
  commission: 1,
  payment: 2,
  funding: 3,
  getTransactionType: (tId) => {
    switch (tId) {
      case 1:
        return "Commission";
      case 2:
        return "Payment";
      case 3:
        return "Funding";
      default:
        return "";
    }
  },
};

export const PAYMENT_STATUS = {
  pending: 1,
  approved: 2,
  declined: 3,
  failed: 4,
  paid: 5,
  getPaymentStatus: (pId) => {
    switch (pId) {
      case 1:
        return "Pending";
      case 2:
        return "Approved";
      case 3:
        return "Declined";
      case 4:
        return "Failed";
      case 5:
        return "Paid";
      default:
        return "";
    }
  },
};

export const decodePaymentFrequency = (freqCode) => {
  switch (freqCode) {
    case 1:
      return "Daily";
    case 2:
      return "Weekly";
    case 3:
      return "Monthly";
    case 4:
      return "Yearly";
    case 5:
      return "Biennially";
    case 6:
      return "Endlessly";
    case 7:
      return "Quarterly";
    default:
      return "";
  }
};

export const getDiscountedPrice = (price, discount) => {
  return price - (discount / 100) * price;
};

export const setCookie = (name, value, exDays, domain, path) => {
  if (!domain) domain = window.location.hostname;
  if (!path) path = "/";

  Cookies.set(name, value, { path, domain, expires: exDays });

  // let d = new Date();
  // d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
  // let expires = "expires="+d.toUTCString();
  // document.cookie = `${name}=${value}; Expires=${expires}; Domain=${domain}; Path=${path};`;

  //console.log("SETTING COOKIE",name,value,exDays,domain,path);
};

export const getCookie = (_name = DOMAIN_COOKIE_NAME) => {
  // let name = _name + "=";
  // let ca = document.cookie.split(';');
  // for(let i = 0; i < ca.length; i++) {
  //   let c = ca[i];
  //   //clean spaces
  //   while (c.charAt(0) === ' ') {
  //     c = c.substring(1);
  //   }
  //   //get cookie value
  //   if (c.indexOf(name) === 0) {
  //     return c.substring(name.length, c.length);
  //   }
  // }
  // return "";
  return Cookies.get(_name);
};

export const deleteCookie = (name, domain) => {
  //setCookie(name,"",-10000,domain);
  Cookies.remove(name, { domain });
};

class UtilityService {
  getOccupations() {
    return Promise.resolve(occupations);
    //return axios.get(`${BASE_URL_RELIANCE}/utility/occupations`);
  }

  getMaritalStatuses() {
    return Promise.resolve(maritalStatuses);
    //return axios.get(`${BASE_URL_RELIANCE}/utility/marital_statuses`);
  }

  getUtilities() {
    return Promise.all([
      this.getOccupations(),
      this.getMaritalStatuses(),
    ]).then((responses) => {
      return {
        occupations: responses[0].data,
        mStatuses: responses[1].data,
      };
    });
  }

  getPlans() {
    return axios.get(`${BASE_URL_RELIANCE}/plans`).then((response) => {
      return response.data.data;
    });
  }

  getPlanById(id, PLANS) {
    if (!PLANS) {
      this.getPlans().then((plans) => this.getPlanById(id, plans));
    } else {
      return PLANS.filter((plan) => plan.id === id)[0];
    }
  }

  getProviders(accessToken) {
    return axios
      .get(
        `${BASE_URL_RELIANCE_V3}/utility/providers`, {
          Authorization: `Bearer ${accessToken}`,
        }
      )
      .then((response) => {
        console.log(response.data.data);
        return response.data.data;
      });
  }

  getExtensions() {
    return axios.get(`${BASE_URL_RELIANCE}/extensions`).then((response) => {
      return response.data.data;
    });
  }

  getBanks() {
    return axios.get(`${BASE_URL_RELIANCE}/utility/banks`).then((response) => {
      return response.data.data;
    });
  }

  uploadFile(accessToken, file, uploadType) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_use", uploadType);
    return axios
      .post(`${BASE_URL_RELIANCE}/upload`, formData, {
        header: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return response.data.data.filename;
      })
      .catch((e) => {
        return {
          message: e.response.data.message || "An Error Occurred",
          code: e.response.status,
        };
      });
  }
}

export default new UtilityService();
