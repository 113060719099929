import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, change} from 'redux-form';
import utilityService from '../../services/utilityService';
import ImageInput from './inputs/ImageInput';
import DateTime from 'react-datetime';
import './inputs/styles/datepicker.css';
import moment from 'moment';
import LoadingScreen from "../LoadingScreen";
import FilterableSelect from "./inputs/FilterableSelect";


class SignUpForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      lastPage: 4, //can vary depending on the enrollee type,
      isLoadingSection: {profilePicture: false}
    }
  }

  onNextPage = (e) => {
    e.preventDefault();
    let nextPage = this.state.currentPage + 1;
    this.setState({
      currentPage: nextPage
    })
  };

  onPrevPage = () => {
    let prevPage = this.state.currentPage - 1;
    this.setState({
      currentPage: prevPage
    })
  };

  componentDidMount() {

    const {dispatch, user} = this.props;

    dispatch(change('signUpForm', 'first_name', user.first_name));
    dispatch(change('signUpForm', 'last_name', user.last_name));
    dispatch(change('signUpForm', 'email_address', user.email_address));
    dispatch(change('signUpForm', 'primary_phone_number', user.phone_number));
    // dispatch(change('signUpForm', 'access_token', user.accessToken));
    if (user.hash) {
      // dispatch(change('signUpForm', 'enrollee_type', user.enrolleeType));
      dispatch(change('signUpForm', 'hash', user.hash));
    }
    //dispatch(change('signUpForm','sex','m'));
    dispatch(change('signUpForm', 'has_smartphone', '1'));
  }

  onImageUploaded = (img) => {
    const {dispatch, user, onError} = this.props;
    //set isLoadingSection in state to true
    //upload img
    //get filename from server
    //change form value
    //set isLoadingSection to false

    this.setState({
      isLoadingSection: {...this.state.isLoadingSection, profilePicture: true}
    });

    utilityService.uploadFile(user.accessToken, img, "profile_picture")
      .then(filename => {
        dispatch(change('signUpForm', 'profile_picture_filename', filename));
        dispatch(change('signUpForm', 'profile_picture', img));
        this.setState({
          error: null,
          isLoadingSection: {...this.state.isLoadingSection, profilePicture: false}
        });
      })
      .catch(e => {
        onError({
          message: "Unable to upload picture.",
          code: -1
        });
        this.setState({
          isLoadingSection: {...this.state.isLoadingSection, profilePicture: false}
        });
        console.log("Error uploading image", e);
      })
  };

  onDateSet = (date) => {
    const {dispatch} = this.props;
    dispatch(change('signUpForm', 'date_of_birth', moment(date).format("YYYY-MM-DD")));
  };


  render() {
    const {
      error,
      selectedState,
      dateOfBirth,
      profilePicture,
      preferredHospitalLocation,
      data,
      enrolleeType,
      handleSubmit,
      user,
      pristine,
      submitting,
      states,
      providers
    } = this.props;
   

    const {currentPage, lastPage, isLoadingSection} = this.state;

    return (
      <form onSubmit={currentPage !== lastPage ? this.onNextPage : handleSubmit} className="form">
        <div style={{display: "flex", flexDirection: "row", justifyContent: "evenly"}}>
          {
            [1, 2, 3, 4].map((x) => {
              return <PageIndicator key={x} isActive={x === currentPage}/>
            })
          }
        </div>
        {currentPage === 1 &&
        <PersonalInfoPage
          user={user}
          data={data}
          onDateSet={this.onDateSet}
          dob={dateOfBirth}/>
        }
        {currentPage === 2 && <ContactInfoPage selectedState={selectedState} user={user} data={data} states={states}/>}
        {currentPage === 3 &&
        <MedicalInfoPage
          user={user}
          data={data}
          providers={providers}
          selectedState={selectedState}
          preferredHospitalLocation={preferredHospitalLocation}/>
        }
        {currentPage === 4 &&
        <ExtraInfoPage
          user={user}
          data={data}
          profilePicture={profilePicture}
          onImageSet={this.onImageUploaded}
          isLoadingPhoto={isLoadingSection.profilePicture}
          enrolleeType={enrolleeType}/>
        }

        {error &&
        <div style={{backgroundColor: "red", color: "#FFF", padding: "10px"}}>
          {error}
        </div>
        }
        <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
          <Field component="input" type="hidden" name="page" value={currentPage}/>
          {currentPage > 1 &&
          <input type="button" value="Previous" onClick={this.onPrevPage} style={{float: "left"}}/>}

          <div style={{width: "100%", textAlign: "center"}}>{`${currentPage} / ${lastPage}`}</div>

          {(currentPage >= 1 && currentPage < lastPage) &&
          <input type="submit" value="Next" style={{float: "right"}}/>}
          {currentPage === lastPage &&
          <input type="submit" value="Submit" disabled={pristine || submitting} style={{float: "right"}}/>}
        </div>
      </form>
    )
  }


}

const Required = () => (<span style={{color: "red", fontWeight: "bold"}}>*</span>);


const PersonalInfoPage = (props) => {
  const {user, dob} = props;

  return (
    <div style={{marginTop: "15px"}}>
      <h2 style={{fontSize: "24px", color: "#094063"}}>Personal Information</h2>
      <p style={{fontStyle: "italic", fontSize: "10px", color: "#aaa"}}><strong>NOTE:</strong>Fields marked (<Required/>)
        are required.</p>
      <div style={{marginTop: "10px"}}>
        <span>
                First Name <Required/>:
            <Field component="input" type="text" name="first_name" placeholder="First Name"
                   readOnly={!!user.first_name} required/>
            <br/>
        </span>
        <span>
            Last Name <Required/>:
            <Field component="input" type="text" name="last_name" placeholder="Last Name"
                   readOnly={!!user.last_name} required/>
            <br/>
        </span>
        <span>
            Sex <Required/>:<br/>
            <Field name="sex" component="input" type="radio" value="m"/>
            {' '}Male {' '}
            <Field name="sex" component="input" type="radio" value="f"/>
            {' '} Female<br/>
        </span>
        <span>
            Date of Birth <Required/>:
            <Field component="input" type="hidden" name="date_of_birth" placeholder="Date of Birth" required/>
            <DateTime
              closeOnSelect
              viewMode="years"
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
              inputProps={{required: true, placeholder: "YYYY-MM-DD", readOnly: true}}
              onChange={props.onDateSet}
              isValidDate={(curr) => curr.year() <= DateTime.moment().year()}
              value={dob}
            />
            <br/>
        </span>
      </div>
    </div>
  )
};


const ContactInfoPage = (props) => {
  const {user, states} = props;
  return (
    <div style={{marginTop: "15px"}}>
      <h2 style={{fontSize: "24px", color: "#094063"}}>Contact Information</h2>
      <p style={{fontStyle: "italic", fontSize: "10px", color: "#aaa"}}><strong>NOTE:</strong>Fields marked (<Required/>)
        are required.</p>
      <div style={{marginTop: "10px"}}>
        <span>
            Phone Number (Primary) <Required/>:
            <Field
              component="input"
              name="primary_phone_number"
              type="phone"
              placeholder={user.accessToken ? "Phone Number (Use yours, if not available)" : "Primary Phone Number"}
              readOnly={!!user.phone_number} normalize={normalizePhone} required/>
            <br/>
        </span>
        <span>
            Email Address:
            <Field
              component="input"
              name="email_address"
              type="email"
              placeholder={user.accessToken ? "Email Address (Leave empty, if unavailable)" : "Email Address"}
              readOnly={!!user.email_address}/>
            <br/>
        </span>
        <span>
            Home Address <Required/>:
            <Field component="input" name="home_address" type="text" placeholder="Home Address" required/>
            <br/>
        </span>
        <span>
            State of Residence <Required/>
            <Field name="home_address_state_code" component="select" required>
                <option>Please select a State</option>
              {states.map((state) => (
                <option key={state.code} value={state.name}>{state.name}</option>
              ))}
            </Field>
        </span>
      </div>
    </div>
  )
};


const MedicalInfoPage = (props) => {
  const {providers} = props;
  let providersList = providers.filter( p => p.state.name.toLowerCase() === props.selectedState.toLowerCase());
  return (
    <div style={{marginTop: "15px"}}>
      <h2 style={{fontSize: "24px", color: "#094063"}}>Choose a Preferred Hospital</h2>
      <p style={{fontStyle: "italic", fontSize: "10px", color: "#aaa"}}><strong>NOTE:</strong>Fields marked (<Required/>)
        are required.</p>
      <div style={{marginTop: "10px"}}>
        {(providersList && providersList.length > 0) ?
          (
            <div className="form-group">
              <Field
                name="preferred_provider_id"
                className="form-input"
                component={FilterableSelect}
                props={
                  {
                    items:providersList,
                    placeholder:"Search by name, address, location or state",
                    filterBy:["name","address","state.name"],
                    extraKey:"address"
                  }
                }/>
            </div>
          ) : (
            <div>
              <span>
                  Preferred Hospital <Required/>:
                  <Field component="input" type="text" name="preferred_hospital_name"
                         placeholder="Preferred Hospital's Name"/>
                  <br/>
              </span>
              <span>
                Hospital Address <Required/>:
                <Field component="input" type="text" name="preferred_hospital_address"
                       placeholder="Preferred Hospital's Address"/>
                <br/>
              </span>
            </div>
          )
        }

        <br/>
      </div>
    </div>
  )
};


const ExtraInfoPage = (props) => {

  const {data, profilePicture, onImageSet, enrolleeType, isLoadingPhoto} = props;
  console.log(data);

  return (
    <div style={{marginTop: "15px"}}>
      <h2 style={{fontSize: "24px", color: "#094063"}}>Extra Information</h2>
      <p style={{fontStyle: "italic", fontSize: "10px", color: "#aaa"}}><strong>NOTE:</strong>Fields marked (<Required/>)
        are required.</p>
      <div style={{marginTop: "10px"}}>

        {enrolleeType !== "3" &&
        <span>
          Marital Status:
          <Field name="marital_status_id" component="select">
              <option>Please select a Marital Status</option>
            {data.mStatuses.map((status) => (
              <option key={status.id} value={status.id}>{status.name}</option>
            ))}
          </Field>
        </span>
        }

        <Field component="file" type="hidden" name="profile_picture"/>
        <Field component="input" type="hidden" name="profile_picture_filename"/>

        {
          isLoadingPhoto ? (
            <div style={{width: "250px", height: "250px"}}>
              <LoadingScreen/>
            </div>
          ) : (
            <span>
              Please add a recent Passport Photograph <Required/>
              <ImageInput
                width={250}
                height={250}
                onCropComplete={onImageSet}
                placeholder={profilePicture}/>
            </span>
          )
        }

        <Field component="input" type="hidden" name="enrollee_type"/>
        <Field component="input" type="hidden" name="hash"/>
        <Field component="input" type="hidden" name="access_token"/>

        <span>
          Do the Enrollee own a Smart-phone (Android, iOS etc):<br/>
          {'  '}
          <Field name="has_smartphone" component="input" type="radio" value="1"/>
          {' '}Yes {'  '}
          <Field name="has_smartphone" component="input" type="radio" value="0"/>
          {' '} No
        </span>
      </div>
    </div>
  )
};


const PageIndicator = (props) => {
  return (
    <div style={{
      backgroundColor: props.isActive ? "#094063" : "#e6e5e5",
      height: "5px",
      borderRadius: "3px",
      width: "100%",
      margin: "4px"
    }}> </div>
  )
};


const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  return value.replace(/[^\d]/g, '');
};


SignUpForm = reduxForm({
  form: 'signUpForm',
  destroyOnUnmount: false,
})(SignUpForm);

// Decorate with connect to read form values
const selector = formValueSelector('signUpForm');
SignUpForm = connect(state => {
  // can select values individually
  const selectedState = selector(state, 'home_address_state_code');
  const dateOfBirth = selector(state, 'date_of_birth');
  const profilePicture = selector(state, 'profile_picture');
  const preferredHospitalLocation = selector(state, 'preferred_hospital_location');
  return {
    selectedState,
    dateOfBirth,
    profilePicture,
    preferredHospitalLocation
  }
})(SignUpForm);

export default SignUpForm