import React from 'react';
import {reduxForm, Field} from 'redux-form';

let ForgotPasswordForm = (props) => {

    const {handleSubmit,pristine,submitting} = props;

    return (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <Field component="input" type="text" name="username" placeholder="Your HMO ID or Email Address" required/>
                <button type="submit" disabled={pristine||submitting}>Reset Password</button>
            </form>
        </div>
    )
};


ForgotPasswordForm = reduxForm({
    form:'forgotPasswordForm'
})(ForgotPasswordForm);

export default ForgotPasswordForm