import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MainContent from '../boxes/MainContent';
import PageHeader from '../boxes/PageHeader';
import PageBody from '../boxes/PageBody';
import NotificationBanner from '../boxes/NotificationBanner';
import { SyncLoader } from 'react-spinners';

// import LoadingScreen from '../LoadingScreen';

let AddEnrolleeForm = ({handleSubmit, plans, setPlans, failedMessage, onDismiss, isLoadingPlans}) => {
  return (
    <MainContent>
      <PageHeader title="Add Enrollee(s)"/>
      <PageBody>
      { failedMessage &&
          <NotificationBanner
            title={"Error!"}
            message={failedMessage}
            type={"error"}
            onDismiss={onDismiss}
          />
       }
        <div className="dashboard-card">
            <form 
              onSubmit={handleSubmit}
              className="dashboard-form"
            >
              <div style={{ display: "flex" }}>
                <div className="dashboard-form-item-wrapper" style={{ marginRight: "3rem"}}>
                  <div className="dashboard-input-wrap profile-input-wrap">
                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        First Name
                      </label>
                      <Field component="input" className="dashboard-form__input" type="text" placeholder="Enter first name..." name="first_name" required/>
                    </span>

                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Last Name
                      </label>
                      <Field component="input" className="dashboard-form__input" placeholder="Enter last name..." name="last_name" required/>
                    </span>

                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Email Address
                      </label>
                      <Field component="input" className="dashboard-form__input"  placeholder="Enter email address..." name="email_address" required/>
                    </span>

                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Phone Number
                      </label>
                      <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter phone number..." name="phone_number" required/>
                    </span>
                  </div>
                </div>

                <div className="dashboard-form-item-wrapper" >
                  <div className="dashboard-input-wrap profile-input-wrap">
                    <Field component="input" type="hidden" name="type" value="family"/>

                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Select Package
                      </label>
                      <Field
                        component="select"
                        name="selected_package"
                        onChange={(e) => (setPlans(e.target.value))}
                        className="dashboard-form__input dashboard-form__select"
                      >
                        
                        <option hidden>Please select a package</option>
                        <option value="retail">Reliance HMO Retail</option>
                        <option value="custom">Reliance HMO Custom</option>
                      </Field>
                     
                    </span>

                    {
                      isLoadingPlans && plans.length === 0 && 
                        <span className="dashboard-form-item" style={{marginTop: "40px"}}>
                          <SyncLoader 
                          color={"#094063"}
                          size={10}
                          />
                        </span>
                    }

                    {
                      isLoadingPlans === false && plans.length === 0 && 
                        <span className="dashboard-form-item">
                          <h3>There is currently no plan available in this category</h3>
                        </span>
                    }

                    {
                      plans.length > 0 &&                
                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Select Plan
                      </label>

                    
                      <Field
                        component="select"
                        name="plan_id"
                        className="dashboard-form__input dashboard-form__select"
                      >
                        <option>Please select a plan</option>
                        {
                          plans.map((plan, index) => (

                            <option value={plan.id} key={plan.id}>{plan.name}</option>
                          ))
                        }
                      </Field>
                    </span>
                    }

                    { plans.length > 0 &&     
                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Select Payment Frequency
                      </label>
                      <Field
                        component="select"
                        name="payment_frequency"
                        className="dashboard-form__input dashboard-form__select"
                      >
                        <option>Please select a payment frequency</option>
                        <option value="m">Monthly </option>
                        <option value="q">Quarterly</option>
                        <option value="a">Annually</option>
                      </Field>
                    </span>
                    }

                    { plans.length > 0 &&    
                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Do you want to complete this enrollee's profile on their behalf?
                      </label>
                      <Field
                        component="select"
                        name="can_complete_profile"
                        className="dashboard-form__input dashboard-form__select"
                        normalize={value => value === 'true'}
                      >
                        <option>Please select an option</option>
                        <option value={true}>Yes </option>
                        <option value={false}>No</option>
                    
                      </Field>
                    </span>
                    }
                    
                    

                    {/* <span className="dashboard-form-item">
                      <label htmlFor="" className="profile-item__label">Do you want to complete this enrollee's profile on their behalf?</label>
                      <span className="dashboard-form__checklist-wrap">
                        <span className="dashboard-form-item__radio-check-btn">
                          <label htmlFor="yes">Yes</label>
                          <Field component="input"  type="radio" name="can_complete_profile" className="" value="true" id="yes"/>
                        </span>
                        <span className="dashboard-form-item__radio-check-btn">
                          <label htmlFor="no">No</label>
                          <Field component="input"  type="radio" name="can_complete_profile" className="" value="false" id="no"/>
                        </span>
                      </span>
                    </span> */}

                    <button
                      className="dashboard__primary-btn dashboard-form__btn"
                      type="submit"
                      disabled= {plans.length === 0}
                     >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
      </PageBody>
    </MainContent>
  );
}

AddEnrolleeForm = reduxForm({
  form: "addEnrolleeForm"
})(AddEnrolleeForm);

export default AddEnrolleeForm;