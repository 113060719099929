import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import NotificationBanner from '../components/boxes/NotificationBanner';
import EnrolleeDetailComponent from '../components/EnrolleeDetailComponent';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import {ApiService, ENDPOINTS } from '../services/ApiService';
import DecisionModal from '../components/modals/DecisionModal';


class EnrolleeDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    const {user: {preferences : { selectedPartner: { credentials } }}, user} = props;
    this.service = new ApiService(user.credentials.access_token,credentials.api_key,credentials.secret_key);
    this.state = {
      selectedEnrollee: {},
      isLoading: true,
      notification: null,
      showDecisionModal: false
    }
  }

  componentDidMount() {
    this.getSelectedEnrollee();
  }

  onRenew = () => {
    const { match: {params: {enrolleeId} } } = this.props;
    const enrollees =  { 
      enrollees: [
          {
            user_id: Number(enrolleeId)
          }
        ]
    };
    this.setLoading(true);
    this.service.put(ENDPOINTS.plans.renewPlan, enrollees)
      .then((response) => {
        this.setLoading(false);
        this.setState({
          showDecisionModal: false,
          notification: {
            title: "Renewal Successful",
            message: "The enrollee's plan has successfully been renewed.",
            type: "success"
          }
        }, () => {
          setTimeout(() => {
            window.location.reload(true);
          });
        }, 2000);

      })
      .catch((error) => {
        console.error(error.message);
        this.setState({
          isLoading: false,
          notification: {
            title: "Renewal Failed",
            message: "The enrollee's plan wasn't renewed. Please contact support at hello@reliancehmo.com.",
            type: "error"
          }
        })
      })
      
  };

  getSelectedEnrollee() {
    const { match: { params: { enrolleeId } } } = this.props;

    this.setLoading(true);

    this.service.get(ENDPOINTS.enrollees.enrolleeDetails(enrolleeId))
    .then((response) => {
      this.setState({
        selectedEnrollee: response.data,
        isLoading: false
      })
    })
    .catch((error) => {
      this.setState({
        error: {
         message: error.message,
         status: error.status
        }
      })
    })
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading() {
    return (
      <MainContent>
        <PageBody>
          <LoadingScreen/>
        </PageBody>
      </MainContent>
    )
  };

  onCompleteProfile = (enrolleeId, hash) => {
    this.props.history.push(`/dashboard/enrollees/${enrolleeId}/complete-profile?hash=${hash}`);
  };

  toggleDecisionModal = () => {
    this.setState({
      showDecisionModal: !this.state.showDecisionModal
    })
  }

  render() {

    const { isLoading, selectedEnrollee, notification, showDecisionModal} = this.state;

    if (!isLoading || !_.isEmpty(selectedEnrollee)) {
      return (
        <MainContent>
          <PageHeader title={`${selectedEnrollee.first_name} ${selectedEnrollee.last_name}'s Profile`}/>
          <PageBody>

            { notification &&
              <NotificationBanner
                title={notification.title}
                message={notification.message}
                type={notification.type}
                onDismiss={() => this.dismissNotificationBanner}
                />
            }

            {
              showDecisionModal &&
                <DecisionModal
                  title="Renew plan"
                  onYes={this.onRenew}
                  onClose={this.toggleDecisionModal}
                  content=""
                  isVisible={showDecisionModal}
                />
            }

            <div className="dashboard-card button-header">
              {moment(selectedEnrollee.cover_end_date).diff(moment(),'days') < 0 &&
              <button
                onClick={this.toggleDecisionModal}
                className="dashboard__primary-btn">
                <i className="fa fa-exchange" style={{color: "white"}}/>
                {'  '}
                Renew Plan
              </button>
              }
            </div>


            <EnrolleeDetailComponent
              selectedEnrollee={selectedEnrollee}
              onCompleteProfile={this.onCompleteProfile}
              />
            <br/>
          </PageBody>
        </MainContent>
      );
    }

    return this.renderLoading();
  }
}


export default EnrolleeDetailContainer;