import React from 'react';
import queryString from 'querystring';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import { ENDPOINTS } from "../services/ApiService";
import AsyncTable from "../components/utils/AsyncTable";
import {BASE_URL_RELIANCE_PUBLIC} from "../services/utilityService";
import SearchBar from '../components/SearchBar';


const tableColumnData = [
  {
    key:"first_name",
    type:"string"
  },
  {
    key:"last_name",
    type:"string"
  },
  {
    key:"phone_number",
    type:"string"
  },
  {
    key:"plan_name",
    type:"string"
  },
  {
    key: "cover_end_date",
    type:"string"
  },
  {
    key:"hmo_id",
    type:"string",
    alias:"Profile Status",
    format:(hmoID) => {
      return !hmoID ? "Incomplete" : "Complete"
    }
  },
  {
    key:"status",
    type:"string",
    alias:"Cover Status"
  }
];


class EnrolleeListContainer extends React.Component {
  constructor(props) {
    super(props);
    const agent = queryString.parse(props.location.search.substr(1)).agent;
    this.state = {
      filter:"",
      requestUrl: `${BASE_URL_RELIANCE_PUBLIC}/public${ENDPOINTS.enrollees.getEnrollees}?${agent ? "referral_code="+agent : ""}`
    }
  }


  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  onAddEnrollee = () => {
    this.props.history.push(`/dashboard/enrollees/add-enrollee`);
  };

  setEnrolleesUrl = (filter, searchQuery) => {
    const { location } = this.props;  
    const agent = queryString.parse(location.search.substr(1)).agent;
    const query = {};
    if(agent){
      query.referral_code = agent;
    }

    if(searchQuery){
      query.q = searchQuery;
    }

    if(filter){
      query.status=filter;
    }
    this.setState({
      filter,
      requestUrl: `${BASE_URL_RELIANCE_PUBLIC}/public${ENDPOINTS.enrollees.getEnrollees}?${queryString.stringify(query)}`
    });
  };


  render() {
    const { user: { preferences: { selectedPartner } }, history } = this.props;
    const {filter, requestUrl} = this.state;

    return (
      <MainContent>
        <PageHeader
          title="Enrollees"
          showButton
          buttonTitle={"Add Enrollee"}
          onButtonClick={this.onAddEnrollee}/>
        <PageBody>
          <div className="dashboard-card button-header">
            <SearchBar 
              clearSearch={(value) =>this.setEnrolleesUrl(filter, value)} 
              onSearch={(value) => this.setEnrolleesUrl(filter, value)} />
            <div style={{display:"flex", alignItems:"center", marginTop:"2em"}}>
              <p style={{margin:"0 1rem"}}>Filter:</p>
              <select
                className="dashboard__primary-btn"
                value={filter}
                onChange={(e) => this.setEnrolleesUrl(e.target.value)}>
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="expired">Expired</option>
              </select>
            </div>
          </div>


          <AsyncTable
            dataSource={{
              url: requestUrl,
              options:{
                auth:{
                  username:selectedPartner.credentials.api_key,
                  password:selectedPartner.credentials.secret_key
                }
              }
            }}
            styleClass="dashboard-table"
            paginationDataKey="meta"
            onItemClick={(enrollee) => history.push(`/dashboard/enrollees/${enrollee.id}`)}
            isPaginated
            limit={10}
            paginationKeys={{
              limit:"limit",
              page:"page",
              lastPage:"pages",
              currentPage:"current_page"
            }}
            tableColumnData={tableColumnData}/>

        </PageBody>
      </MainContent>
    )
  }
}

export default EnrolleeListContainer;