import { createTypes, async } from 'redux-action-types';
import * as ACTION_CATEGORIES from './actionCategories';

export const USER_ACTION_TYPES = createTypes(ACTION_CATEGORIES.USER+'_',
    async('LOGIN'),
    async('DAARA_LOGOUT'),
    async('RESET_PASSWORD'),
    async('GET_OVERVIEW'),
    async('GET_USAGE'),
    async('UPDATE_PROFILE'),
    'START_LOADING',
    'STOP_LOADING'
);

export const ENROLLEE_ACTION_TYPES = createTypes(ACTION_CATEGORIES.ENROLLEES+'_',
    async('GET_ENROLLEES'),
    async('GET_ENROLLEE_BY_ID'),
    async('EDIT_ENROLLEE'),
    async('TRANSFER_ENROLLEE'),
    async('REMOVE_ENROLLEE'),
    async('REMOVE_ENROLLEE_EXTENSION'),
    'START_LOADING',
    'STOP_LOADING'
);

export const INVOICE_ACTION_TYPES = createTypes(ACTION_CATEGORIES.INVOICES+'_',
    async('GET_INVOICES'),
    async('GET_INVOICE_BY_REF_CODE'),
    async('GET_INVOICE_PLANS'),
    async('ADD_EMPLOYEE_TO_INVOICE')
);

export const DISPLAY_ACTION_TYPES = createTypes(ACTION_CATEGORIES.DISPLAY+'_',
    'TOGGLE_SIDEBAR',
    'HIDE_SIDEBAR'
);