import {ENROLLEE_ACTION_TYPES} from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  allEnrollees: [],
  selectedEnrollee: {},
  error: {},
  alert: ""
});

export default function enrolleeReducer(state = initialState, action) {
  let newEnrollee = Immutable.asMutable(state.selectedEnrollee,{deep:true});
  switch (action.type) {
    case ENROLLEE_ACTION_TYPES.GET_ENROLLEES:
    case ENROLLEE_ACTION_TYPES.GET_ENROLLEE_BY_ID:
    case ENROLLEE_ACTION_TYPES.EDIT_ENROLLEE:
    case ENROLLEE_ACTION_TYPES.TRANSFER_ENROLLEE:
    case ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE:
    case ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_EXTENSION:
    case ENROLLEE_ACTION_TYPES.START_LOADING:
      return Immutable(state).merge({
        isFetching: true,
        error: null,
        alert: ""
      });
    case ENROLLEE_ACTION_TYPES.GET_ENROLLEES_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        allEnrollees: [...action.data],
        error: null,
        alert: ""
      }, {deep: true});
    case ENROLLEE_ACTION_TYPES.GET_ENROLLEES_FAIL:
      return Immutable(state).merge({
        isFetching: false,
        error: action.data,
        alert: ""
      });
    case ENROLLEE_ACTION_TYPES.GET_ENROLLEE_BY_ID_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        selectedEnrollee: action.data,
        error: null,
        alert: ""
      });
    case ENROLLEE_ACTION_TYPES.GET_ENROLLEE_BY_ID_FAIL:
      return Immutable(state).merge({
        isFetching: false,
        error: action.data,
        alert: ""
      });
    case ENROLLEE_ACTION_TYPES.EDIT_ENROLLEE_SUCCESS:
      newEnrollee = Immutable.asMutable(state.selectedEnrollee);
      newEnrollee.first_name = action.data.first_name;
      newEnrollee.last_name = action.data.last_name;
      newEnrollee.phone_number = action.data.phone_number;
      return Immutable(state).merge({
        isFetching: false,
        selectedEnrollee: newEnrollee,
        error: "",
        alert: "Details successfully Updated!"
      });
    case ENROLLEE_ACTION_TYPES.TRANSFER_ENROLLEE_SUCCESS:
    case ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        alert: action.data,
        error: null
      });
    case ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_EXTENSION_SUCCESS:
      let removedExtIndex = -1;
      newEnrollee.extensions.map((ext,index)=>{
        if(ext.id === action.data.id)
          removedExtIndex = index;
        return ext;
      });
      newEnrollee.extensions.splice(removedExtIndex,1);
      return Immutable(state).merge({
        isFetching: false,
        selectedEnrollee: newEnrollee,
        alert: action.data.message,
        meta: {
          removedExtensionId: action.data.id
        },
        error: null
      });
    case ENROLLEE_ACTION_TYPES.EDIT_ENROLLEE_FAIL:
    case ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_FAIL:
    case ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_EXTENSION_FAIL:
    case ENROLLEE_ACTION_TYPES.TRANSFER_ENROLLEE_FAIL:
      return Immutable(state).merge({
        isFetching: false,
        error: action.data,
        alert: ""
      }, {deep: true});
    case ENROLLEE_ACTION_TYPES.STOP_LOADING:
      return Immutable(state).merge({
        isFetching: false,
        alert: "",
        error: null
      }, {deep: true});
    default:
      return state;
  }
}
