import axios from 'axios';
import * as _ from 'lodash';
import {
    BASE_URL_RELIANCE,
    getCookie,
    DAARA_COOKIE_NAME,
    DAARA_TOKEN_PREFIX
} from './utilityService';
import { features } from './daara-config.json';

const BASE_URL = BASE_URL_RELIANCE;

class UserService {
    constructor() {
        this.ax = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                DAARA_TOKEN: getCookie(DAARA_COOKIE_NAME)
            }
        });
        this.data = {};
    }

    auth(accessToken = getCookie()) {
        const daaraToken = getCookie(DAARA_COOKIE_NAME);

        return this.ax
            .post(`/auth/partners`, undefined, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                response.data.data.basic_info.access_token = accessToken;
                response.data.data.basic_info.daara_token = daaraToken;
                if (features.account_service.auth) {
                    localStorage.setItem(`${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`, daaraToken);
                }

                return mapUser(response.data);
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    resetPassword(values) {
        return this.ax.post('/accounts/recover-password', values).then(() => {
            return true;
        });
    }

    getEnrollees(accessToken) {
        return this.ax
            .get('/enrollees', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getEnrolleeById(accessToken, uId) {
        return this.ax
            .get(`/enrollees/${uId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getInvoices(accessToken) {
        return this.ax
            .get('/invoices', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getInvoiceByRefCode(accessToken, refCode) {
        return this.ax
            .get(`/invoices/${refCode}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    addEnrolleeToInvoice(accessToken, json) {
        return this.ax
            .post('/enrollees', json, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e, e.response.data.data);
            });
    }

    editEnrolleeDetails(accessToken, userId, json) {
        return this.ax
            .put(`/enrollees/${userId}`, json, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getPlans() {
        return this.ax
            .get('/plans')
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    buyPlansAndExtensions(accessToken, json) {
        return this.ax
            .post('/cart', json, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    changePassword(accessToken, json) {
        return this.ax
            .post('/accounts/change-password', json, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    transferEnrollee(accessToken, eId) {
        return this.ax
            .post(`/enrollees/${eId}/transfer`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    removeEnrolleeExtension(accessToken, eId, extId) {
        return this.ax
            .delete(`/enrollees/${eId}/extensions/${extId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    removeEnrollee(accessToken, eId) {
        return this.ax
            .delete(`/enrollees/${eId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    unhashParams(accessToken, hash) {
        if (hash) {
            return this.ax
                .post(
                    '/enrollees/unhash',
                    { hash },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                .then((response) => {
                    return {
                        email_address: response.data.data.email_address,
                        phone_number: response.data.data.phone_number,
                        first_name: response.data.data.first_name,
                        last_name: response.data.data.last_name,
                        is_family_plan: response.data.data.is_family_plan,
                        company_name: response.data.data.company_name,
                        providers: response.data.data.providers
                    };
                })
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        } else {
            return null;
        }
    }

    register(accessToken, values) {
        //delete profile picture file
        delete values.profile_picture;
        //delete access_token from post body
        delete values.access_token;

        return this.ax
            .post(`/enrollees/profile`, values, {
                headers: {
                    // "Authorization": `Bearer ${accessToken}`
                    Authorization: `Basic YTlkODBiOjQ3YjIyODAyNmU5YmJiNzIxODEx`
                }
            })
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    renewPlan(invoiceRefCode) {
        return this.ax.get(`payment/manual/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }

    resetPlan(invoiceRefCode) {
        return this.ax.post(`/retail/renew/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }

    toggleAutoBilling(activate, invoiceRefCode) {
        return this.ax
            .get(`/payment/${activate ? 'subscribe' : 'unsubscribe'}/${invoiceRefCode}`)
            .then((response) => {
                return response.data.message;
            });
    }

    changePaymentFrequency(accessToken, newFreq, invoiceRefCode) {
        return this.ax
            .put(
                `/invoices/${invoiceRefCode}`,
                { frequency: newFreq },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingPay(accessToken, refCode) {
        return this.ax
            .post(
                `/enrollees/billing/pay`,
                {
                    reference_code: refCode
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingRefund(accessToken, refCode, accName, accNumber, bankId) {
        return this.ax
            .post(
                `/enrollees/billing/refund`,
                {
                    reference_code: refCode,
                    account_name: accName,
                    account_number: accNumber,
                    bank_id: bankId
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingDismiss(accessToken, refCode) {
        return this.ax
            .post(
                `/enrollees/billing/dismiss`,
                {
                    reference_code: refCode
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }
}

function mapUser(json) {
    let partners = _.get(json, 'data.clients');
    return {
        id: _.get(json, 'data.basic_info.id'),
        firstName: _.get(json, 'data.basic_info.first_name'),
        lastName: _.get(json, 'data.basic_info.last_name'),
        roles: _.get(json, 'data.roles'),
        email: _.get(json, 'data.basic_info.email_address'),
        phoneNumber: _.get(json, 'data.basic_info.phone_number'),
        referralCode: _.get(json, 'data.basic_info.referral_code'),
        partners,
        credentials: {
            accessToken: _.get(json, 'data.basic_info.access_token'),
            daaraToken: _.get(json, 'data.basic_info.daara_token')
        },
        preferences: {
            allowNotifications: '',
            selectedPartner: partners.length === 1 ? partners[0] : null
        }
    };
}

function UserServiceException(e, _meta) {
    if (!e.response)
        e = {
            response: {
                status: 0,
                statusText: 'Unknown Error',
                data: { message: 'An Error Occurred' }
            }
        };
    this.code = e.response.status;
    this.message = e.response.data.message || e.response.statusText;
    this.meta = _meta;
}

export default new UserService();
