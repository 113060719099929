import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {DISPLAY_ACTION_TYPES} from '../../actions/actionTypes';

class PageHeader extends React.Component{

    toggleSideBar = () => {
        const {dispatch} = this.props;
        dispatch({type:DISPLAY_ACTION_TYPES.TOGGLE_SIDEBAR});
    };

    render(){
        const {title, showButton, onButtonClick, buttonTitle} = this.props;

        return (
            <div className="top-right content">
                <div className="header">
                    <div className="header__left">
                        <button className="toggle-nav" onClick={this.toggleSideBar}>&#9776;</button>
                        <div className="header__title">
                            <h2>{title}</h2>
                        </div>
                    </div>
                    <div className="header__right">
                        {
                            showButton &&
                            <div>
                                <button className="dashboard__primary-btn" onClick={onButtonClick}>{buttonTitle}</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

PageHeader.propTypes = {
    title: PropTypes.string,
    showButton: PropTypes.bool,
    onButtonClick: PropTypes.func,
    buttonTitle: PropTypes.string
};


export default connect()(PageHeader)