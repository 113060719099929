import React from 'react';


class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: ""
        };     
    }

    handleChange = (value) => {
      if(value.length === 0){
        this.clearSearch();
      }
      this.setState({
        value
      });
    };
  
    clearSearch = () => {
      const {clearSearch} = this.props;
      if(clearSearch){
        clearSearch();
        this.setState({
          value:""
        })
      }
    };
       
    pressEnter = (event) => {
        if (event.key === 'Enter') { 
          this.props.onSearch(event.target.value);
        };
      }  

    render(){
      const {value} = this.state;
        return(
          <div className="dashboard-btn-section search-section">
            <input
              placeholder="Search for Enrollees"
              className="dashboard-form__input"
              type="text"
              value={value}
              onChange={(e) => this.handleChange(e.target.value)}
              onKeyPress={this.pressEnter}/>
        { value.length > 0 &&
          <span className="clear" >
            <i className="fa fa-times" onClick={this.clearSearch} style={{color:"#094063"}}/>
          </span>
        }
      </div>
        );
        
    }
}

export default SearchBar;







