import React, { Component } from 'react';
import { ApiService, ENDPOINTS } from '../services/ApiService';
import AddEnrolleeForm from './forms/AddEnrolleeForm';
import LoadingScreen from './LoadingScreen';
import MainContent from './boxes/MainContent';
import {
  PLAN_TYPE_INDIVIDUAL,
  PACKAGE_TYPE_RETAIL} from '../services/utilityService';
class AddEnrolleeComponent extends Component {
  constructor(props){
    super(props);
    const {user: {preferences : { selectedPartner: { credentials } }}} = props;
    this.service = new ApiService(credentials.api_key,credentials.secret_key);
    this.state = {
      enrollees: null,
      isLoading: false,
      isFetching: false,
      completeProfile: null,
      plans: [],
      hideNotificationBanner: true
    }
  }

  renderLoading() {
    return (
      <MainContent>
        <LoadingScreen/>
      </MainContent>
    )
  }

  setPlans = (selectedPackage=PACKAGE_TYPE_RETAIL) => {
    this.setState({
      isLoadingPlans : true
    })
    const queryData = {
      type: PLAN_TYPE_INDIVIDUAL,
      package: selectedPackage,
    };
    this.service.get(ENDPOINTS.plans.getPlans, queryData)
      .then((response) => {
        this.setState({
          plans: response.data.plans,
          isLoadingPlans: false
        });
      })
      .catch((error) => {
        console.log(error);
      })
  };

  onSubmitForm = (values) => {
    this.setState({
      isLoading: true,
      completeProfile: values.can_complete_profile
    });

    this.service.post(ENDPOINTS.enrollees.signUpEnrollee, {
      referral_code: this.props.user.referralCode,
      enrollees: [{...values, dependants: []}]
    })
      .then(response => {
        //reset sign up form on successful submission
        // dispatch(reset('signUpForm'));
        // dispatch(clearUserProfile());
        this.setState({
          status: response.status,
          isLoading: false,          
          error: null,
          successful: true,
         
        });

        const hash = response.data.enrollees.successful.length === 0 ? [] : response.data.enrollees.successful[0].hash;
        const enrolleeId = response.data.enrollees.successful.length === 0 ? [] : response.data.enrollees.successful[0].user_id;

        if (response.data.enrollees.successful.length > 0) { 
          this.state.completeProfile ?
            this.props.history.push(`/dashboard/enrollees/${enrolleeId}/complete-profile?hash=${hash}`)
            : 
            this.props.history.push(`/dashboard/enrollees`);
        }
        if(response.data.enrollees.failed.length > 0){
          this.setState({
            failedMessage: response.data.enrollees.failed[0].message
          })
        }

      })

      .catch((error) => {
         this.setState({
            error: true,
            message: error.message,
            code: error.code,
            isLoading: false,
            
         });
         console.error(error.message);
      });
  };

  dismissNotificationBanner = () => {
    this.setState({
      hideNotificationBanner: true
    });
  }

  render() {
    const {isLoading, failedMessage} = this.state;
    if(isLoading) return (
      <MainContent>
        <LoadingScreen/>
      </MainContent>
    );

    return (
      <AddEnrolleeForm
        onSubmit={this.onSubmitForm}
        plans={this.state.plans}
        isLoadingPlans={this.state.isLoadingPlans}
        setPlans={this.setPlans}
        failedMessage={failedMessage}
        onDismiss={this.dismissNotificationBanner}
      />
    );
  }
}

export default AddEnrolleeComponent;